import { useConfigStore } from '../pinia/configuration';

export function useCompanyLogo() {
 const appConfig = useConfigStore().appConfig;
 const theme = appConfig.cssTheme;
 const logoSrc = `/themes/${theme}/images/logo.png`;
 const logoSrcSet = `/themes/${theme}/images/logo@2x.png 2x, /themes/${theme}/images/logo@3x.png 3x`;
const secondaryLogoSrc = `/themes/${theme}/images/logo-secondary.png`;
const secondaryLogoSrcSet = `/themes/${theme}/images/logo_secondary@2x.png 2x, /themes/${theme}/images/logo_secondary@3x.png 3x`;
return { logoSrc, logoSrcSet, secondaryLogoSrc, secondaryLogoSrcSet, theme };
}

